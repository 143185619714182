<template>
  <div>
    <base-loading v-if="loading" />
    <div v-else>
      <span v-if="form.value">
        {{ title }} {{ form.value }}{{ field.prefix }}
      </span>
      <sup v-if="history">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" small class="mx-2" color="primary" v-on="on">
              mdi-history
            </v-icon>
          </template>
          <span
            >Esse campo foi alterado na data de
            {{ moment(new Date(history)).format('lll') }}</span
          >
        </v-tooltip>
      </sup>

      <v-dialog v-if="!draft" v-model="dialog" persistent max-width="290">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="relatorio.structure != 'old'"
            class="mx-2"
            icon
            fab
            small
            v-bind="attrs"
            v-on="on"
          >
            <v-icon dark> mdi-cog </v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="text-h5">
            Atualizar {{ field.label }}
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="form.value"
              :label="field.label"
              :type="field.type"
              :rules="field.rules"
            ></v-text-field>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="dialog = false">
              Cancelar
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              :loading="btnLoading"
              :disabled="btnLoading"
              @click="update"
            >
              Atualizar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import reportService from '../../services/api/reports'

export default {
  name: 'UpdateFields',

  props: {
    id: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      default: '',
    },

    field: {
      type: Object,
      required: true,
    },

    draft: {
      type: Boolean,
      default: false,
    },

    relatorio: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      moment,
      dialog: false,

      form: {
        value: this.relatorio[this.field.property],
      },

      loading: false,

      btnLoading: false,

      history: null,
    }
  },

  mounted() {
    if (this.relatorio.structure == 'old') return

    this.index()
  },

  methods: {
    async index() {
      if (this.draft) return

      const history = this.relatorio?.history?.find(
        h => h.key == this.field.key,
      )

      if (!history) return

      this.history = history.updatedAt
    },

    async update() {
      this.btnLoading = true

      try {
        const fieldUpdate = {
          key: this.field.key,
          label: this.field.label,
          value: this.form.value,
        }

        await reportService.updateFields({
          id: this.id,
          fields: [fieldUpdate],
        })

        this.Toast().fire({
          icon: 'success',
          title: 'Campo atualizado com sucesso!',
        })

        this.dialog = false
        this.history = new Date()
        this.relatorio[this.field.property] = this.form.value
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Erro ao atualizar campo!' + error,
        })
      } finally {
        this.btnLoading = false
      }
    },
  },
}
</script>
