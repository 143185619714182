import HtmlToCanvas from 'html2canvas'
import JsPDF from 'jspdf'
import moment from 'moment'
import { ENV_APP } from '../../../env'
import { convertNumberBR } from '../../utils/masks'

const utilsRelatorio = {
  getDataAtual() {
    var monName = [
      'janeiro',
      'fevereiro',
      'março',
      'abril',
      'maio',
      'junho',
      'julho',
      'agosto',
      'setembro',
      'outubro',
      'novembro',
      'dezembro',
    ]
    var now = new Date()

    var str =
      'Impresso em ' +
      now.getDate() +
      ' de ' +
      monName[now.getMonth()] +
      ' de ' +
      now.getFullYear() +
      ' às ' +
      formatDate(now, 'HH:mm:ss')
    return str
  },
  calcLacVacas(relatorio) {
    if (relatorio.VacasLactacao && relatorio.VacasSecas) {
      return `${convertNumberBR(
        100 *
          (parseInt(relatorio.VacasLactacao) /
            (parseInt(relatorio.VacasLactacao) +
              parseInt(relatorio.VacasSecas))),
      )} %`
    } else {
      return '-'
    }
  },
  calcLacRebanho(relatorio) {
    var totalRebanho = 0
    if (relatorio.VacasLactacao) {
      totalRebanho += parseInt(relatorio.VacasLactacao)
    }
    if (relatorio.VacasSecas) {
      totalRebanho += parseInt(relatorio.VacasSecas)
    }
    if (relatorio.Novilhas) {
      totalRebanho += parseInt(relatorio.Novilhas)
    }
    if (relatorio.Recria) {
      totalRebanho += parseInt(relatorio.Recria)
    }
    if (relatorio.Aleitamento) {
      totalRebanho += parseInt(relatorio.Aleitamento)
    }
    if (relatorio.Machos) {
      totalRebanho += parseInt(relatorio.Machos)
    }
    if (totalRebanho > 0) {
      return `${convertNumberBR(
        100 * (parseInt(relatorio.VacasLactacao) / totalRebanho),
      )} %`
    } else {
      return '-'
    }
  },
  calcProdutividade(relatorio) {
    if (relatorio.VacasLactacao && relatorio.ProducaoMedia) {
      return `${convertNumberBR(
        parseInt(relatorio.ProducaoMedia) / parseInt(relatorio.VacasLactacao),
      )} L / dia`
    } else {
      return '-'
    }
  },
  calcProdutividadeTotal(relatorio) {
    var totalVacas = 0
    if (relatorio.VacasLactacao) {
      totalVacas += parseInt(relatorio.VacasLactacao)
    }
    if (relatorio.VacasSecas) {
      totalVacas += parseInt(relatorio.VacasSecas)
    }
    if (relatorio.ProducaoMedia && totalVacas > 0) {
      return `${convertNumberBR(
        parseInt(relatorio.ProducaoMedia) / totalVacas,
      )} L / dia`
    } else {
      return '-'
    }
  },
  calcProdutividadeDH(relatorio) {
    if (relatorio.NumeroMDO && relatorio.ProducaoMedia) {
      return `${convertNumberBR(
        parseInt(relatorio.ProducaoMedia) / parseInt(relatorio.NumeroMDO),
      )}`
    } else {
      return '-'
    }
  },
}

function formatDate(date, format = 'DD/MM/YYYY') {
  return moment(date).format(format)
}

function header(doc, config, relatorio) {
  const logo1 = new Image()
  const logo2 = new Image()

  logo1.src = config.logo

  doc.addImage(logo1, 'PNG', config.margin, config.margin, 70, 55)
  doc.setFontSize(config.largefontSize).setFont('helvetica', 'bold')

  const widthOfString = doc.getTextWidth('Relatório lactalis')

  doc.text(
    'Relatório lactalis',
    config.tableLimit / 2 - widthOfString / 2 + config.margin,
    config.margin + 30,
  )

  if (relatorio.logoagroindustria) {
    logo2.src = relatorio.logoagroindustria
    doc.addImage(logo2, 'PNG', config.tableLimit - 50, config.margin, 73, 50)
  }

  doc.setFontSize(config.fontSize).setFont('helvetica', 'normal')
}

function subHeader(doc, _ref, _ref2) {
  const {
    fazenda: {
      produtor: { Nome: produtor },
      pessoajuridica: { NomeFantasia: fazenda },
      producer: { Matricula: matricula },
    },
    consultor: {
      pessoafisica: { Nome: consultor },
    },
    cidadefazenda,
    HoraInicio,
    HoraFim,
    NumeroVisita,
  } = _ref
  const {
    boxHeight,
    gutterY,
    line,
    lineColor,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
    mediumFontSize,
    fontSize,
  } = _ref2

  const dataAtendimento = _ref.DataAtendimento

  doc.setFillColor(lineColor)

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Informações básicas', startX, startY + 10)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  doc.rect(startX, startY + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight, line, boxHeight, 'F')

  doc.text('Fazenda:', startX + smallGutterX, startY + smallGutterY + boxHeight)

  doc
    .setFont('helvetica', 'bold')
    .text(fazenda, startX + smallGutterX, startY + gutterY + boxHeight)
    .setFont('helvetica', 'normal')

  doc.rect(startX, startY + boxHeight * 3, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    startY + boxHeight,
    line,
    boxHeight,
    'F',
  )

  doc.rect(startX, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 2, tableLimit, line, 'F')

  doc.text(
    'Consultor(a):',
    startX + smallGutterX,
    startY + smallGutterY + boxHeight * 2,
  )

  doc.text(consultor, startX + smallGutterX, startY + gutterY + boxHeight * 2)

  doc.text(
    'Matrícula:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + smallGutterY + boxHeight,
  )

  doc.text(
    matricula ? matricula : '-',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + gutterY + boxHeight,
  )

  doc.rect(startX + tableLimit, startY + boxHeight, line, boxHeight, 'F')
  doc.rect(startX + tableLimit, startY + boxHeight * 2, line, boxHeight, 'F')
  doc.rect(startX + tableLimit, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.text(
    'Produtor(a):',
    startX + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )
  doc.text(
    produtor.substring(0, 25),
    startX + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  // doc.rect(
  //   startX + tableLimit / 3,
  //   startY + boxHeight * 2,
  //   line,
  //   boxHeight,
  //   'F',
  // )

  doc.rect(
    startX + tableLimit / 3,
    startY + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Cidade:',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    cidadefazenda ? cidadefazenda.Nome : '-',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    startY + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Data:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    dataAtendimento || '-',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 4, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 4, line, boxHeight, 'F')

  doc.text(
    'Horário do início:',
    startX + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    HoraInicio || '-',
    startX + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 3,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Horário do término:',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    HoraFim || ' - ',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Número da visita:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    `${NumeroVisita}ª visita`,
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )
  doc.rect(startX + tableLimit, startY + boxHeight * 4, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 5, tableLimit, line, 'F')
}

function indicadoresQualidade(doc, relatorio, _ref2) {
  const {
    boxHeight,
    fontSize,
    line,
    gutterY,
    mediumFontSize,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
  } = _ref2

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Principais Indicadores', startX, startY + 10)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  doc.rect(startX, startY + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Produção média do último mês:',
    startX + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    relatorio.ProducaoMedia
      ? `${relatorio.ProducaoMedia} L / Dia`
      : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit / 3, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'CPP média do último mês:',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    relatorio.CppMedia ? `${relatorio.CppMedia} UFC/ml * 10³` : 'Não informado',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    startY + boxHeight,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'CCS média do último mês:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    relatorio.CcsMedia
      ? `${relatorio.CcsMedia} céls/ml * 10³`
      : 'Não informado',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 2, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.text(
    'Número de pessoas envolvidas no manejo de rebanho:',
    startX + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    relatorio.NumeroMDO ? `${relatorio.NumeroMDO}` : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Área para produção de leite:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    relatorio.AreaProducao ? `${relatorio.AreaProducao} ha` : 'Não informado',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 3, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.text(
    'Produtividade/DH:',
    startX + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    `${utilsRelatorio.calcProdutividadeDH(relatorio)}`,
    startX + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Produtividade / Área:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    relatorio.ProducaoMedia && relatorio.AreaProducao
      ? `${convertNumberBR(
          (parseInt(relatorio.ProducaoMedia) * 365) /
            parseInt(relatorio.AreaProducao),
        )} L / ha / ano`
      : '-',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 4, tableLimit, line, 'F')
}

function composicaoRebanho(doc, relatorio, _ref2) {
  const {
    boxHeight,
    fontSize,
    line,
    gutterY,
    mediumFontSize,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
  } = _ref2

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Composição do rebanho', startX, startY + 10)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  const cursor = startY + boxHeight + 170

  doc.rect(startX, cursor + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, cursor + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Vacas em lactação:',
    startX + smallGutterX,
    cursor + boxHeight + smallGutterY,
  )

  doc.text(
    `${relatorio.VacasLactacao}`,
    startX + smallGutterX,
    cursor + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit / 3, cursor + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Vacas secas:',
    startX + tableLimit / 3 + smallGutterX,
    cursor + boxHeight + smallGutterY,
  )

  doc.text(
    `${relatorio.VacasSecas}`,
    startX + tableLimit / 3 + smallGutterX,
    cursor + boxHeight + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    cursor + boxHeight,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Novilhas:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    cursor + boxHeight + smallGutterY,
  )

  doc.text(
    `${relatorio.Novilhas}`,
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    cursor + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit, cursor + boxHeight, line, boxHeight, 'F')

  doc.rect(startX, cursor + boxHeight * 2, tableLimit, line, 'F')

  doc.rect(startX, cursor + boxHeight * 2, line, boxHeight, 'F')

  doc.text(
    'Machos:',
    startX + smallGutterX,
    cursor + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    `${relatorio.Machos}`,
    startX + smallGutterX,
    cursor + boxHeight * 2 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 3,
    cursor + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Animais em recria:',
    startX + tableLimit / 3 + smallGutterX,
    cursor + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    `${relatorio.Recria}`,
    startX + tableLimit / 3 + smallGutterX,
    cursor + boxHeight * 2 + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    cursor + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Animais em aleitamento:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    cursor + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    `${relatorio.Aleitamento}`,
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    cursor + boxHeight * 2 + gutterY,
  )

  doc.rect(startX + tableLimit, cursor + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX, cursor + boxHeight * 3, tableLimit, line, 'F')

  doc.rect(startX, cursor + boxHeight * 3, line, boxHeight, 'F')

  doc.text(
    'Vacas em lactação / total de vacas:',
    startX + smallGutterX,
    cursor + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    `${utilsRelatorio.calcLacVacas(relatorio)}`,
    startX + smallGutterX,
    cursor + boxHeight * 3 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    cursor + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Vacas em lactação / Total do rebanho:',
    startX + tableLimit / 2 + smallGutterX,
    cursor + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    `${utilsRelatorio.calcLacRebanho(relatorio)}`,
    startX + tableLimit / 2 + smallGutterX,
    cursor + boxHeight * 3 + gutterY,
  )

  doc.rect(startX + tableLimit, cursor + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX, cursor + boxHeight * 4, tableLimit, line, 'F')

  doc.rect(startX, cursor + boxHeight * 4, line, boxHeight, 'F')

  doc.text(
    'Produtividade / vacas em lactação:',
    startX + smallGutterX,
    cursor + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    `${utilsRelatorio.calcProdutividade(relatorio)}`,
    startX + smallGutterX,
    cursor + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    cursor + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Produtividade / total de vacas:',
    startX + tableLimit / 2 + smallGutterX,
    cursor + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    `${utilsRelatorio.calcProdutividadeTotal(relatorio)}`,
    startX + tableLimit / 2 + smallGutterX,
    cursor + boxHeight * 4 + gutterY,
  )

  doc.rect(startX + tableLimit, cursor + boxHeight * 4, line, boxHeight, 'F')

  doc.rect(startX, cursor + boxHeight * 5, tableLimit, line, 'F')

  return cursor + boxHeight * 5
}

function proximaVisitaAssinaturas(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    tableLimit,
    heightLimit,
    margin,
    startX,
    smallGutterY,
    line,
  } = config

  cursor += config.boxHeight
  if (cursor + boxHeight * 7 > heightLimit) {
    doc.addPage()
    cursor = margin
  }
  const proximaVisita = relatorio.AssinaturaProdutorOuResponsavel
    ? relatorio.DataProximaVisita
    : relatorio.DataProximaVisita

  doc.text('Data da próxima visita', tableLimit / 2 - 30, cursor + boxHeight)
  doc.text(
    proximaVisita,
    tableLimit / 2 - 5,
    cursor + boxHeight + smallBoxHeight,
  )

  cursor += boxHeight * 2

  const consultor = new Image()
  consultor.src = relatorio.AssinaturaConsultor
  if (relatorio.AssinaturaConsultor) {
    doc.addImage(
      consultor,
      'PNG',
      tableLimit / 2 - 30,
      cursor - 140,
      100,
      145,
      '',
      'FAST',
      270,
    )
  }

  doc.rect(startX + 130, cursor + 100, tableLimit / 2, line, 'F')

  doc.text(
    relatorio.consultor.pessoafisica.Nome,
    tableLimit / 2 - relatorio.consultor.pessoafisica.Nome.length,
    cursor + 100 + smallGutterY,
  )

  doc.text(
    'Assinatura consultor(a)',
    tableLimit / 2 - 23,
    cursor + 115 + smallGutterY,
  )

  cursor += boxHeight * 2

  return cursor
}

function rodapeImpressao(doc, config, cursor, text) {
  const { boxHeight, heightLimit, margin } = config
  const docWidth = doc.internal.pageSize.width
  const texto =
    utilsRelatorio.getDataAtual() +
    '       ' +
    `${ENV_APP.relUri}` +
    '        Pag. ' +
    text

  if (cursor + boxHeight * 2 > heightLimit) {
    doc.addPage()
    cursor = margin
  }

  doc.text(texto, docWidth / 2, heightLimit + 10, 'center')
}

export default data => {
  return new Promise(function (resolve, reject) {
    const MARGIN = 25
    const realStartY = 95
    var doc = new JsPDF({
      format: 'a4',
      compress: true,
      unit: 'pt',
    })
    var config = {
      startY: realStartY,
      startX: MARGIN,
      smallGutterY: 12,
      smallGutterX: 6,
      line: 0.3,
      tableLimit: doc.internal.pageSize.width - MARGIN - MARGIN,
      heightLimit: doc.internal.pageSize.height - MARGIN,
      margin: MARGIN,
      lineColor: '#000',
      boxHeight: 27,
      smallBoxHeight: 18,
      gutterX: 14,
      gutterY: 23,
      smallFontSize: 6.5,
      fontSize: 10,
      largefontSize: 16,
      mediumFontSize: 12,
      logo: 'img/' + `${ENV_APP.imgFileLogin}`,
      bodyStarY: realStartY + 25 * 8.5,
    }

    try {
      HtmlToCanvas(document.querySelector('#chart-rebanho')).then(canvas => {
        var dataURL = canvas.toDataURL()
        doc.addImage(
          dataURL,
          'PNG',
          config.startX * 3,
          config.startY + config.boxHeight * 9 + 40,
          440,
          190,
        )
        var cursor = 0
        header(doc, config, data)
        subHeader(doc, data, config)
        config.startY = config.startY + config.boxHeight * 4 + 35
        indicadoresQualidade(doc, data, config)
        config.startY = config.startY + config.boxHeight * 4 + 20
        cursor = composicaoRebanho(doc, data, config)
        config.startY = cursor + config.boxHeight

        cursor = proximaVisitaAssinaturas(doc, data, config, cursor)
        const pageCount = doc.internal.getNumberOfPages()

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i)
          rodapeImpressao(
            doc,
            config,
            cursor + config.boxHeight,
            String(i) + ' de ' + String(pageCount),
          )
        }
        doc
          .save(
            `relatorio-lactalis-${data.NumeroVisita}-${data.fazenda.pessoajuridica.NomeFantasia}.pdf`,
            { returnPromise: true },
          )
          .then(() => {
            return resolve(true)
          })
      })
    } catch (err) {
      reject(err)
    }
  })
}
