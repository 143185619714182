var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.relatorio.ProducaoMedia ||
    _vm.relatorio.CppMedia ||
    _vm.relatorio.CcsMedia ||
    _vm.relatorio.NumeroMDO ||
    _vm.relatorio.AreaProducao
  )?_c('v-card',{staticClass:"ma-4"},[_c('v-card-title',{staticStyle:{"text-align":"left"}},[_vm._v("Principais Indicadores")]),_c('v-card-text',[_c('v-row',{staticClass:"pa-3"},[_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('span',{staticClass:"key-title"},[_vm._v("Produção média do último mês: ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.ProducaoMedia ? _vm.relatorio.ProducaoMedia + ' L / Dia' : 'Não informado'))])]),_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('span',{staticClass:"key-title"},[_vm._v("CPP média do último mês: ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_c('UpdateFields',{attrs:{"id":_vm.relatorio.id,"relatorio":_vm.relatorio,"title":"UFC/ml * 10³","field":{
              key: 'cppMedia',
              property: 'CppMedia',
              label: 'CPP média do último mês',
              type: 'number',
            },"draft":_vm.draft}})],1)]),_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('span',{staticClass:"key-title"},[_vm._v("CCS média do último mês: ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_c('UpdateFields',{attrs:{"id":_vm.relatorio.id,"title":"céls/ml * 10³","relatorio":_vm.relatorio,"field":{
              key: 'ccsMedia',
              property: 'CcsMedia',
              label: 'CCS média do último mês',
              type: 'number',
            },"draft":_vm.draft}})],1)]),_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"key-title"},[_vm._v("Número de pessoas envolvidas no manejo de rebanho: ")]),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.NumeroMDO ? _vm.relatorio.NumeroMDO : 'Não informado'))])]),_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"key-title"},[_vm._v("Área para produção de leite: ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.AreaProducao ? _vm.relatorio.AreaProducao + ' ha' : 'Não informado'))])]),_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"key-title"},[_vm._v("Produtividade/DH: ")]),_c('br'),_vm._v(" "+_vm._s(_vm.calcProdutividade())+" ")]),_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"key-title"},[_vm._v("Produtividade / Área: ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.ProducaoMedia && _vm.relatorio.AreaProducao ? `${_vm.convertNumberBR( (parseInt(_vm.relatorio.ProducaoMedia) * 365) / parseInt(_vm.relatorio.AreaProducao), )} L / ha / ano` : '-'))])])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }